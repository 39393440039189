<template>
  <!-- Filter card -->
  <b-card>
    <h3 class="mb-1 font-weight-bolder">
      {{ $t('Global.Filters') }}
    </h3>
    <!-- Filter row -->
    <b-row class="filter-gap">
      <!-- Cost Centre filter -->
      <b-col lg="2" md="2" sm="12">
        <label for="filter-cost-centre">
          {{ $t('Management.Layers.CostCentre') }}
        </label>
        <v-select
          id="filter-cost-centre"
          v-model="filter.costCentre"
          :options="costCentres"
          :placeholder="
            $t('Global.Select', {
              subject: $t('Management.Layers.CostCentre'),
            })
          "
        />
      </b-col>

      <!-- Department filter -->
      <b-col lg="2" md="2" sm="12">
        <label for="filter-department">
          {{ $t('Management.Layers.Department') }}
        </label>
        <v-select
          id="filter-department"
          v-model="filter.department"
          :options="departments"
          :placeholder="
            $t('Global.Select', {
              subject: $t('Management.Layers.Department'),
            })
          "
        />
      </b-col>

      <!-- Status filter -->
      <b-col lg="2" md="2" sm="12">
        <label for="filter-status">
          {{ $t('Management.Layers.Status') }}
        </label>
        <v-select
          id="filter-status"
          v-model="filter.isActive"
          :reduce="option => option.value"
          :options="[
            {
              label: $t('Management.Layers.Active'),
              value: true,
            },
            {
              label: $t('Management.Layers.Inactive'),
              value: false,
            },
          ]"
          :placeholder="
            $t('Global.Select', {
              subject: $t('Management.Layers.Status'),
            })
          "
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
import vSelect from 'vue-select'

import { optionFactory } from '@/views/loggedin/management/layers/utility'

export default {
  name: 'LayerFilter',

  components: {
    BRow,
    BCard,
    BCol,
    vSelect,
  },

  props: {
    filter: {
      type: Object,
      required: true,
    },
    layers: {
      type: Array,
      required: true,
    },
  },

  computed: {
    /**
     * Calculate options for the costCentres filter.
     * @returns {Array} Array of costCentre options.
     */
    costCentres() {
      return optionFactory(this.layers, 'costCentre')
    },

    /**
     * Calculate options for the departments filter.
     * @returns {Array} Array of department options.
     */
    departments() {
      return optionFactory(this.layers, 'department')
    },
  },
}
</script>

<style lang="scss">
.filter-gap {
  gap: 1.5rem;
}
</style>
