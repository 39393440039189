<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="ml-2 d-flex align-items-center"
      variant="primary"
      @click="openSideBar"
    >
      <feather-icon class="mr-1" icon="DatabaseIcon" size="21" />
      {{ $tc('Global.New') }} {{ $t('Management.Layers.Layer') }}
    </b-button>
    <Form
      ref="form"
      :layer="layer"
      :can="$can($acl.action.Create, $acl.subjects.Layers)"
      @handle-submit="handleSubmit"
      @reset="reset"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Form from './form.vue'

const emptyLayerFactory = () => ({
  area: null,
  site: null,
  street: null,
  houseNumber: null,
  zipCode: null,
  city: null,
  active: false,
  costCentre: null,
  configuration: {
    attachOrder: false,
    canManagePastAttendance: false,
    blocks: [],
    groups: [],
    layers: [],
    lines: [],
    surCharge: {},
  },
  department: null,
})

export default {
  components: {
    Form,
    BButton,
  },

  data: () => ({
    layer: emptyLayerFactory(),
  }),

  methods: {
    reset() {
      this.layer = emptyLayerFactory()
    },

    openSideBar() {
      this.$refs.form.sidebarVisible = true
    },

    async handleSubmit() {
      try {
        this.layer.houseNumber = parseInt(this.layer.houseNumber, 10) || null
        const { data } = await this.$axios.post('layers', this.layer)
        this.$alert.create(`${data.area} ${data.site || ''}`)
        this.$emit('add', data)

        const { form } = this.$refs
        form.dirty = false
        form.sidebarVisible = false
      } catch {
        this.$alert.error()
      }
    },
  },
}
</script>
