var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h3',{staticClass:"mb-1 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('Global.Filters'))+" ")]),_c('b-row',{staticClass:"filter-gap"},[_c('b-col',{attrs:{"lg":"2","md":"2","sm":"12"}},[_c('label',{attrs:{"for":"filter-cost-centre"}},[_vm._v(" "+_vm._s(_vm.$t('Management.Layers.CostCentre'))+" ")]),_c('v-select',{attrs:{"id":"filter-cost-centre","options":_vm.costCentres,"placeholder":_vm.$t('Global.Select', {
            subject: _vm.$t('Management.Layers.CostCentre'),
          })},model:{value:(_vm.filter.costCentre),callback:function ($$v) {_vm.$set(_vm.filter, "costCentre", $$v)},expression:"filter.costCentre"}})],1),_c('b-col',{attrs:{"lg":"2","md":"2","sm":"12"}},[_c('label',{attrs:{"for":"filter-department"}},[_vm._v(" "+_vm._s(_vm.$t('Management.Layers.Department'))+" ")]),_c('v-select',{attrs:{"id":"filter-department","options":_vm.departments,"placeholder":_vm.$t('Global.Select', {
            subject: _vm.$t('Management.Layers.Department'),
          })},model:{value:(_vm.filter.department),callback:function ($$v) {_vm.$set(_vm.filter, "department", $$v)},expression:"filter.department"}})],1),_c('b-col',{attrs:{"lg":"2","md":"2","sm":"12"}},[_c('label',{attrs:{"for":"filter-status"}},[_vm._v(" "+_vm._s(_vm.$t('Management.Layers.Status'))+" ")]),_c('v-select',{attrs:{"id":"filter-status","reduce":function (option) { return option.value; },"options":[
          {
            label: _vm.$t('Management.Layers.Active'),
            value: true,
          },
          {
            label: _vm.$t('Management.Layers.Inactive'),
            value: false,
          } ],"placeholder":_vm.$t('Global.Select', {
            subject: _vm.$t('Management.Layers.Status'),
          })},model:{value:(_vm.filter.isActive),callback:function ($$v) {_vm.$set(_vm.filter, "isActive", $$v)},expression:"filter.isActive"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }