<template>
  <span>
    <feather-icon
      v-b-tooltip.hover.top="
        $t(
          `Global.${
            $can($acl.action.Update, $acl.subjects.Layers)
              ? 'EditSubject'
              : 'ViewSubject'
          }`,
          { subject: $t('Management.Layers.Layer') },
        )
      "
      :icon="
        $can($acl.action.Create, $acl.subjects.Layers) ? 'Edit2Icon' : 'EyeIcon'
      "
      size="16"
      class="hover-green"
      @click="openSideBar"
    />
    <Form
      ref="form"
      :layer="updatedData"
      :submit-btn-text="`${$t('Global.Save')} ${$t('Management.Layers.Layer')}`"
      :can="$can($acl.action.Create, $acl.subjects.Layers)"
      :update="true"
      @handle-submit="handleSubmit"
    />
  </span>
</template>

<script>
import Form from './form.vue'

export default {
  components: {
    Form,
  },

  props: {
    layer: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    updatedData: null,
  }),

  methods: {
    openSideBar() {
      this.updatedData = JSON.parse(JSON.stringify(this.layer))
      this.$refs.form.sidebarVisible = true
    },

    async handleSubmit() {
      try {
        if (this.updatedData.houseNumber) {
          this.updatedData.houseNumber = parseInt(
            this.updatedData.houseNumber,
            10,
          )
        }
        const { configuration } = this.updatedData

        if (this.updatedData.surCharge === undefined) {
          this.updatedData.surCharge = {}
        }
        this.updatedData.configuration = undefined
        const { data } = await this.$axios.patch(
          `layers/${this.layer.id}`,
          this.updatedData,
        )
        this.updatedData.configuration = configuration

        this.$alert.update(`${data.area} ${data.site || ''}`)

        Object.keys(this.updatedData).forEach(key => {
          this.layer[key] = this.updatedData[key]
        })

        const { form } = this.$refs
        form.dirty = false
        form.sidebarVisible = false
      } catch {
        this.$alert.error()
      }
    },
  },
}
</script>
