<template>
  <!-- Overlay to show loading state -->
  <b-overlay :show="isLoading">
    <!-- Layer filter component -->
    <LayerFilter :filter="filter" :layers="layers" />

    <!-- Card containing search and table components -->
    <b-card no-body>
      <!-- Custom search section -->
      <div class="d-flex custom-search row mb-2 px-2 pt-2 align-items-center">
        <div class="col-md-6">
          <!-- Title -->
          <h2 class="m-0 font-weight-bolder">
            {{ $t('Global.All') }} {{ $t('Management.Layers.Layers') }}
          </h2>
        </div>
        <div class="col-md-6 d-md-flex justify-content-end">
          <!-- Search input and create button -->
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Global.Search')"
              type="text"
              class="d-inline-block search"
            />
            <Create
              v-if="$can($acl.action.Create, $acl.subjects.Layers)"
              @add="addEntry"
            />
          </div>
        </div>
      </div>

      <!-- Table component -->
      <Table
        :rows="filteredLayers"
        :search-term="searchTerm"
        @delete="handleDelete"
        @configure="handleConfigure"
        @copy="handleCopy"
        @set-status="handleSetStatus"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BFormInput, BOverlay } from 'bootstrap-vue'

import deleteEntry from '@/utility/scripts/delete'
import Create from './components/create.vue'
import LayerFilter from './components/filter.vue'
import Table from './components/table.vue'

export default {
  components: {
    BFormInput,
    BCard,
    BOverlay,

    LayerFilter,
    Create,
    Table,
  },

  data: () => ({
    /**
     * Search term for filtering layers.
     * @type {string}
     */
    searchTerm: '',

    /**
     * Array of layers.
     * @type {Array<Object>}
     */
    layers: [],

    /**
     * Loading state of the page.
     * @type {boolean}
     */
    isLoading: false,

    /**
     * Filter object to filter layers.
     */
    filter: {
      /**
       * Filter by active status.
       * @type {boolean|null}
       */
      isActive: null,

      /**
       * Filter by cost centre.
       * @type {string|null}
       */
      costCentre: null,

      /**
       * Filter by department.
       * @type {string|null}
       */
      department: null,
    },
  }),

  computed: {
    /**
     * Filtered layers based on applied filters.
     * @returns {Array} Array of filtered layers.
     */
    filteredLayers() {
      return this.layers
        .filter(layer => {
          if (this.filter.isActive === null) return true
          return this.filter.isActive === layer.active
        })
        .filter(layer => {
          if (!this.filter.costCentre) return true
          return this.filter.costCentre === layer.costCentre
        })
        .filter(layer => {
          if (!this.filter.department) return true
          return this.filter.department === layer.department
        })
    },
  },

  /**
   * Created lifecycle hook.
   */
  async created() {
    // Load layers data
    await this.load()
  },

  methods: {
    /**
     * Load layers data.
     */
    async load() {
      this.isLoading = true

      try {
        const { data } = await this.$axios.get('layers')
        this.layers = data
      } catch (error) {
        this.$alert.error()
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },

    /**
     * Add a new layer entry.
     * @param {Object} entry - New layer entry.
     */
    addEntry(entry) {
      this.layers.push(entry)
    },

    /**
     * Handle configuring a layer entry.
     * @param {Object} layer - The layer entry.
     */
    handleConfigure(layer) {
      this.$router.push({
        name: 'layers-configure',
        params: { id: layer.id },
      })
    },

    /**
     * Handle copying a layer entry.
     * @param {Object} row - Layer row to be copied.
     */
    async handleCopy(row) {
      /**
       * Subject for confirmation dialog.
       * @type {string}
       */
      const subject = `${row.area} ${row.site || ''}`

      /**
       * Message VNode for confirmation dialog.
       * @type {VNode}
       */
      const messageVNode = this.$createElement('div', {
        class: ['p-1'],
        domProps: {
          innerHTML: this.$t('Management.Layers.CopyQuestion', { subject }),
          style: 'font-size: 16px',
        },
      })

      /**
       * Show confirmation dialog to copy layer entry.
       * @type {boolean|null}
       */
      const choice = await this.$bvModal.msgBoxConfirm([messageVNode], {
        title: this.$t('Management.Layers.Copy'),
        size: 'sm',
        okVariant: 'primary',
        cancelVariant: 'outline-secondary',
        okTitle: this.$t('Management.Layers.Copy'),
        cancelTitle: this.$t('Global.Retreat'),
        hideHeaderClose: false,
        centered: true,
        'body-class': 'p-2',
      })

      if (!choice) return

      try {
        const { data } = await this.$axios.post(`layers/copy/${row.id}`)
        this.layers.push(data)
      } catch (error) {
        this.$alert.error()
        console.error(error)
      }
    },

    /**
     * Handle deleting a layer entry.
     * @param {Object} layer - Layer to be deleted.
     */
    async handleDelete(layer) {
      try {
        /**
         * ID of the deleted layer entry.
         * @type {number}
         */
        const id = await deleteEntry(this, {
          id: layer.id,
          subject: `${this.$t('Management.Layers.Layer')}`,
          individual: `${layer.area} ${layer.site || ''}`,
          endpoint: 'layers/',
        })

        this.layers = this.layers.filter(x => x.id !== id)
      } catch (error) {
        this.$alert.error()
        console.error(error)
      }
    },

    /**
     * Handle setting status of a layer entry.
     * @param {Object} payload - Payload containing layer and isActive status.
     */
    async handleSetStatus({ layer, isActive }) {
      try {
        await this.$axios.patch(`layers/set-active/${layer.id}/${isActive}`)

        const index = this.layers.findIndex(x => x.id === layer.id)

        if (index === -1) return

        this.setPropertyFormLayer(layer.id, 'active', isActive)
      } catch (error) {
        if (error.response.status === 422) {
          await this.$bvModal.msgBoxOk(
            this.$t('Management.Layers.PreventInactiveText'),
            {
              title: this.$t('Management.Layers.PreventInactive'),
              size: 'sm',
              okVariant: 'primary',
              cancelVariant: 'outline-secondary',
              okTitle: this.$t('Global.Ok'),
              cancelTitle: this.$t('Global.Retreat'),
              hideHeaderClose: false,
              centered: true,
              'body-class': 'p-2',
            },
          )
          return
        }

        console.error(error)
        this.$alert.error()
      }
    },

    /**
     * Set property for a layer entry.
     * @param {string} id - ID of the layer entry.
     * @param {string} key - Property key to be set.
     * @param {any} value - Value to be set.
     */
    setPropertyFormLayer(id, key, value) {
      const layers = this.layers.map(x => {
        if (x.id !== id) return x

        return {
          ...x,
          [key]: value,
        }
      })

      // trigger update in components and sub-components
      this.layers = JSON.parse(JSON.stringify(layers))
    },
  },
}
</script>
