<template>
  <b-sidebar
    ref="sidebar"
    shadow
    backdrop
    right
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
    bg-variant="white"
    width="420px"
  >
    <template #header>
      <h3 class="my-1 mx-2 font-weight-bolder">
        <span v-if="!update">{{ $tc('Global.New') }}</span>
        {{ $t('Management.Layers.Layer') }}
        <span v-if="update">{{ $tc('Global.Edit') }}</span>
      </h3>
    </template>
    <validation-observer
      ref="rules"
      tag="form"
      class="px-3 mt-2"
      @submit.prevent="handleSubmit"
    >
      <section v-if="layer">
        <b-form-group
          :label="$t('Management.Layers.Area') + '*'"
          label-for="area"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Layers.Area')"
            rules="required"
          >
            <b-form-input
              id="area"
              v-model="layer.area"
              :placeholder="
                $t('Global.Write', { subject: $t('Management.Layers.Area') })
              "
              :state="errors.length > 0 ? false : null"
              :disabled="!can"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Management.Layers.CostCentre')"
          label-for="costCentre"
        >
          <b-form-input
            id="costCentre"
            v-model="layer.costCentre"
            :placeholder="
              $t('Global.Write', {
                subject: $t('Management.Layers.CostCentre'),
              })
            "
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Management.Layers.Department')"
          label-for="department"
        >
          <b-form-input
            id="department"
            v-model="layer.department"
            :placeholder="
              $t('Global.Write', {
                subject: $t('Management.Layers.Department'),
              })
            "
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-group :label="$t('Management.Layers.Site')" label-for="site">
          <b-form-input
            id="site"
            v-model="layer.site"
            :placeholder="
              $t('Global.Write', { subject: $t('Management.Layers.Site') })
            "
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Management.Layers.Street')"
          label-for="street"
        >
          <b-form-input
            id="street"
            v-model="layer.street"
            :placeholder="
              $t('Global.Write', { subject: $t('Management.Layers.Street') })
            "
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Management.Layers.HouseNumber')"
          label-for="houseNumber"
        >
          <b-form-input
            id="houseNumber"
            v-model="layer.houseNumber"
            :placeholder="
              $t('Global.Write', {
                subject: $t('Management.Layers.HouseNumber'),
              })
            "
            type="tel"
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Management.Layers.ZipCode')"
          label-for="zipCode"
        >
          <b-form-input
            id="zipCode"
            v-model="layer.zipCode"
            :placeholder="
              $t('Global.Write', { subject: $t('Management.Layers.ZipCode') })
            "
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Management.Layers.City')"
          label-for="location"
        >
          <b-form-input
            id="city"
            v-model="layer.city"
            :placeholder="
              $t('Global.Write', {
                subject: $t('Management.Layers.City'),
              })
            "
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
      </section>
    </validation-observer>
    <template #footer>
      <div class="d-flex justify-content-between m-2">
        <b-button
          v-if="can"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="handleSubmit"
        >
          {{ $t('Global.Save') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="sidebarVisible = false"
        >
          {{ $t('Global.Retreat') }}
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { required } from '@validations'
import { BButton, BFormGroup, BFormInput, BSidebar } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { preventUnsavedChangesPopupLevel } from '@/utility/mixins/preventUnsavedChanges'

export default {
  components: {
    BButton,
    BSidebar,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [preventUnsavedChangesPopupLevel],

  props: {
    layer: {
      type: Object,
      default: () => null,
    },
    can: {
      type: Boolean,
      required: true,
    },
    update: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    required,
  }),

  methods: {
    reset() {
      this.$emit('reset')
      this.$refs.rules.reset()
    },

    async handleSubmit() {
      const valid = await this.$refs.rules.validate()

      if (!valid) {
        this.$alert.invalid()
        return
      }

      this.$emit('handle-submit')
    },
  },
}
</script>
