<template>
  <!-- table -->
  <vue-good-table
    :columns="columns"
    :rows="rows"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: pageLength,
    }"
  >
    <div slot="emptystate" class="text-center">
      {{ $t('Global.Emptystate') }}
    </div>
    <template slot="table-row" slot-scope="props">
      <!-- Column: Action -->
      <span v-if="props.column.field === 'city'">
        {{ formatLocation(props.row) }}
      </span>

      <span v-else-if="props.column.field === 'active'">
        <ActiveToggler
          :value="props.row.active"
          :individuell="`${props.row.area} ${props.row.site || ''}`"
          :subject="$t('Management.Layers.Layers')"
          @change-status="handleSetStatus(props.row, $event)"
        />
      </span>

      <span v-else-if="props.column.field === 'lines'">
        {{ props.row.configuration.lines.map(x => x.name).join(', ') }}
      </span>

      <span v-else-if="props.column.field === 'action'" class="action">
        <Update :layer="props.row" />
        <span
          v-if="$can($acl.action.Delete, $acl.subjects.Layers)"
          v-b-tooltip.hover.top="
            $t('Global.DeleteSubject', {
              subject: $t('Management.Layers.Layer'),
            })
          "
        >
          <feather-icon
            icon="TrashIcon"
            size="16"
            class="hover-red"
            @click="handleDelete(props.row)"
          />
        </span>
        <span
          v-if="$can($acl.action.Update, $acl.subjects.Layers)"
          v-b-tooltip.hover.top="$t('Management.Layers.Configure.Configure')"
        >
          <feather-icon
            icon="SettingsIcon"
            size="16"
            class="hover-orange"
            @click="handleConfigure(props.row)"
          />
        </span>
        <span
          v-if="$can($acl.action.Delete, $acl.subjects.Create)"
          v-b-tooltip.hover.top="$t('Management.Layers.Copy')"
        >
          <feather-icon
            icon="CopyIcon"
            size="16"
            class="hover-blue"
            @click="handleCopy(props.row)"
          />
        </span>
      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- pagination -->
    <template slot="pagination-bottom" slot-scope="props">
      <div class="d-flex justify-content-between flex-wrap px-2 pb-2">
        <div class="d-flex align-items-center mb-0 mt-1 flex-wrap">
          <span class="text-nowrap">
            {{ $t('Global.ShowingOneOf') }}
          </span>
          <v-select
            v-model="pageLength"
            class="mx-1"
            style="min-width: 75px"
            :clearable="false"
            :options="['10', '15', '30']"
            @input="value => props.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap">{{ $t('Global.EntriesShown') }}</span>
        </div>
        <div>
          <b-pagination
            :value="1"
            :total-rows="props.total"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="value => props.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import moment from 'moment'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'

import ActiveToggler from '@/components/ActiveToggler/ActiveToggler.vue'
import Update from './update.vue'

export default {
  components: {
    VueGoodTable,
    vSelect,
    Update,
    BPagination,
    ActiveToggler,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    searchTerm: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label: this.$t('Management.Layers.Area'),
          field: 'area',
        },
        {
          label: this.$t('Management.Layers.Site'),
          field: 'site',
        },
        {
          label: this.$t('Management.Layers.Location'),
          field: 'city',
        },
        {
          label: this.$t('Management.Layers.StartDate'),
          field: 'configuration',
          formatFn: this.formatStartDate,
        },
        {
          label: this.$tc('Management.Layers.Configure.Line', 0),
          field: 'lines',
        },
        {
          label: this.$t('Management.Layers.Status'),
          field: 'active',
        },
        {
          label: this.$t('Global.Action'),
          field: 'action',
          sortable: false,
        },
      ],
    }
  },

  methods: {
    handleCopy(layer) {
      this.$emit('copy', layer)
    },

    handleSetStatus(layer, isActive) {
      this.$emit('set-status', {
        layer,
        isActive,
      })
    },

    handleDelete(layer) {
      this.$emit('delete', layer)
    },

    handleConfigure(layer) {
      this.$emit('configure', layer)
    },

    formatStartDate(configuration) {
      if (configuration.startDate) {
        return moment(configuration.startDate, 'YYYY-MM-DD').format(
          'DD.MM.YYYY',
        )
      }
      return null
    },

    formatLocation({ street, houseNumber, zipCode, city }) {
      const front = street && houseNumber ? `${street} ${houseNumber}, ` : null
      const zipCodeString = zipCode ? `${zipCode} ` : ''
      return `${front || ''}${zipCodeString} ${city || ''}`
    },
  },
}
</script>
