var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{ref:"sidebar",attrs:{"shadow":"","backdrop":"","right":"","no-close-on-backdrop":"","no-close-on-esc":"","no-enforce-focus":"","bg-variant":"white","width":"420px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticClass:"my-1 mx-2 font-weight-bolder"},[(!_vm.update)?_c('span',[_vm._v(_vm._s(_vm.$tc('Global.New')))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('Management.Layers.Layer'))+" "),(_vm.update)?_c('span',[_vm._v(_vm._s(_vm.$tc('Global.Edit')))]):_vm._e()])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between m-2"},[(_vm.can)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t('Global.Save'))+" ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.sidebarVisible = false}}},[_vm._v(" "+_vm._s(_vm.$t('Global.Retreat'))+" ")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"rules",staticClass:"px-3 mt-2",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(_vm.layer)?_c('section',[_c('b-form-group',{attrs:{"label":_vm.$t('Management.Layers.Area') + '*',"label-for":"area"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management.Layers.Area'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"area","placeholder":_vm.$t('Global.Write', { subject: _vm.$t('Management.Layers.Area') }),"state":errors.length > 0 ? false : null,"disabled":!_vm.can,"autocomplete":"off"},model:{value:(_vm.layer.area),callback:function ($$v) {_vm.$set(_vm.layer, "area", $$v)},expression:"layer.area"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4042730353)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management.Layers.CostCentre'),"label-for":"costCentre"}},[_c('b-form-input',{attrs:{"id":"costCentre","placeholder":_vm.$t('Global.Write', {
              subject: _vm.$t('Management.Layers.CostCentre'),
            }),"disabled":!_vm.can,"autocomplete":"off"},model:{value:(_vm.layer.costCentre),callback:function ($$v) {_vm.$set(_vm.layer, "costCentre", $$v)},expression:"layer.costCentre"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management.Layers.Department'),"label-for":"department"}},[_c('b-form-input',{attrs:{"id":"department","placeholder":_vm.$t('Global.Write', {
              subject: _vm.$t('Management.Layers.Department'),
            }),"disabled":!_vm.can,"autocomplete":"off"},model:{value:(_vm.layer.department),callback:function ($$v) {_vm.$set(_vm.layer, "department", $$v)},expression:"layer.department"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management.Layers.Site'),"label-for":"site"}},[_c('b-form-input',{attrs:{"id":"site","placeholder":_vm.$t('Global.Write', { subject: _vm.$t('Management.Layers.Site') }),"disabled":!_vm.can,"autocomplete":"off"},model:{value:(_vm.layer.site),callback:function ($$v) {_vm.$set(_vm.layer, "site", $$v)},expression:"layer.site"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management.Layers.Street'),"label-for":"street"}},[_c('b-form-input',{attrs:{"id":"street","placeholder":_vm.$t('Global.Write', { subject: _vm.$t('Management.Layers.Street') }),"disabled":!_vm.can,"autocomplete":"off"},model:{value:(_vm.layer.street),callback:function ($$v) {_vm.$set(_vm.layer, "street", $$v)},expression:"layer.street"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management.Layers.HouseNumber'),"label-for":"houseNumber"}},[_c('b-form-input',{attrs:{"id":"houseNumber","placeholder":_vm.$t('Global.Write', {
              subject: _vm.$t('Management.Layers.HouseNumber'),
            }),"type":"tel","disabled":!_vm.can,"autocomplete":"off"},model:{value:(_vm.layer.houseNumber),callback:function ($$v) {_vm.$set(_vm.layer, "houseNumber", $$v)},expression:"layer.houseNumber"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management.Layers.ZipCode'),"label-for":"zipCode"}},[_c('b-form-input',{attrs:{"id":"zipCode","placeholder":_vm.$t('Global.Write', { subject: _vm.$t('Management.Layers.ZipCode') }),"disabled":!_vm.can,"autocomplete":"off"},model:{value:(_vm.layer.zipCode),callback:function ($$v) {_vm.$set(_vm.layer, "zipCode", $$v)},expression:"layer.zipCode"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management.Layers.City'),"label-for":"location"}},[_c('b-form-input',{attrs:{"id":"city","placeholder":_vm.$t('Global.Write', {
              subject: _vm.$t('Management.Layers.City'),
            }),"disabled":!_vm.can,"autocomplete":"off"},model:{value:(_vm.layer.city),callback:function ($$v) {_vm.$set(_vm.layer, "city", $$v)},expression:"layer.city"}})],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }