export const _ = undefined

/**
 * Creates an array of unique values from the specified key in each object.
 *
 * @param {Array<Object>} array - The array of objects.
 * @param {string} key - The key to extract values from each object.
 * @returns {Array} - An array of unique values extracted from the specified key in each object.
 */
export const optionFactory = (array, key) =>
  array.reduce((acc, layer) => {
    if (layer[key] === undefined || layer[key] === null) return acc

    if (!acc.includes(layer[key])) {
      acc.push(layer[key])
    }

    return acc
  }, [])
